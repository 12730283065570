<template>
  <div>
    <section
      id="content"
      class="z-10 flex h-screen flex-col items-center justify-center bg-white px-4"
    >
      <div class="-mt-32 flex justify-center">
        <img src="@/assets/image/icon.png" class="h-60 rounded-lg" />
      </div>
      <div v-if="showAppVersion" class="absolute bottom-0 flex-none pb-24">
        <p class="text-sm text-gray-300">
          {{ `${appVersion} - ${appMode}` }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SplashScreen',
  props: {
    showAppVersion: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      appVersion: '',
      appMode: '',
    }
  },
  created() {
    this.appVersion = import.meta.env.VITE_APP_VERSION
    this.appMode = import.meta.env.MODE
  },
}
</script>
