<template>
  <SplashScreen />
</template>

<script>
import globalMixin from '@/mixins/global.js'
import SplashScreen from '@/components/SplashComponent.vue'

export default {
  name: 'SplashPage',
  components: {
    SplashScreen,
  },
  mixins: [globalMixin],
  layout: 'main',
  async mounted() {
    // const forgotPinCode = this.$store.getters['auth/forgotPinCode']
    this.$router.push({
      name: 'home',
      query: { lang: this.$route.query.lang },
    })
  },
  methods: {},
}
</script>
