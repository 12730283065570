import Splash from '@/views/Splash.vue'

import NotFound from '@/views/NotFound.vue'
import Login from '@/views/Login.vue'
import Index from '@/views/Index.vue'

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
  {
    path: '/splash',
    name: 'splash',
    meta: { title: 'Splash', layout: 'mobile' },
    component: Splash,
    // example of route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import('./views/About.vue')
  },
  {
    path: '/',
    name: 'home',
    meta: { title: 'Index', layout: 'mobile' },
    component: Index,
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: 'Login', layout: 'blank' },
    component: Login,
  },
  { path: '/404', name: 'not-found', component: NotFound },
  { path: '/:path(.*)', component: NotFound },
]
