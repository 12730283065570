<template>
  <div
    class="absolute z-[100] items-center px-4"
    :class="[
      deviceWidth,
      position === 'center'
        ? 'bottom-[7.3rem]'
        : position === 'top'
        ? 'top-10'
        : 'bottom-0 mb-10',
    ]"
  >
    <!-- Toast Notification Success-->
    <div
      v-if="type === 'success'"
      class="bg-kira-primary z-[100] mb-2 flex w-full items-center rounded-md py-3 px-3 opacity-90 shadow-md"
    >
      <!-- icons -->
      <div class="bg-kira-light text-kira-primary mr-3 rounded-full">
        <svg
          width="1.8em"
          height="1.8em"
          viewBox="0 0 16 16"
          class="bi bi-check"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
          />
        </svg>
      </div>
      <!-- message -->
      <!-- <div v-if="useHtml" class="text-white text-sm max-w-xs" v-html="message"></div>
      <div v-else class="text-white text-sm max-w-xs">
        {{ message }}
      </div> -->
      <div class="text-white text-sm max-w-xs">
        {{ message }}
      </div>
    </div>

    <!-- Toast Notification Info -->
    <div
      v-if="type === 'info'"
      class="bg-kira-secondary z-[100] mb-2 flex w-full items-center rounded-md py-3 px-3 shadow-md"
    >
      <!-- icons -->
      <div class="bg-kira-light text-kira-secondary mr-3 rounded-full">
        <svg
          width="1.8em"
          height="1.8em"
          viewBox="0 0 16 16"
          class="bi bi-info"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
          />
          <circle cx="8" cy="4.5" r="1" />
        </svg>
      </div>
      <!-- message -->
      <div class="text-white text-sm max-w-xs">
        {{ message }}
      </div>
    </div>

    <!-- Toast Notification Warning -->
    <div
      v-if="type === 'warning'"
      class="z-[100] mb-2 flex w-full items-center rounded-md bg-orange-400 py-3 px-3 shadow-md"
    >
      <!-- icons -->
      <div class="bg-kira-light mr-3 rounded-full text-orange-500">
        <svg
          width="1.8em"
          height="1.8em"
          viewBox="0 0 16 16"
          class="bi bi-exclamation"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"
          />
        </svg>
      </div>
      <!-- message -->
      <div class="text-white text-sm max-w-xs">
        {{ message }}
      </div>
    </div>

    <!-- Toast Notification Danger -->
    <div
      v-if="type === 'error'"
      class="bg-kira-danger z-[100] mb-2 flex w-full items-center rounded-md py-3 px-3 shadow-md"
    >
      <!-- icons -->
      <div class="bg-kira-light text-kira-danger mr-3 rounded-full">
        <svg
          width="1.8em"
          height="1.8em"
          viewBox="0 0 16 16"
          class="bi bi-x"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
          />
          <path
            fill-rule="evenodd"
            d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
          />
        </svg>
      </div>
      <div class="text-white text-sm max-w-xs">
        {{ message }}
      </div>
    </div>

    <!-- Toast Notification Default -->
    <div
      v-if="type === 'default'"
      class="bg-kira-dark z-[100] mb-2 flex w-full items-center rounded-lg py-3 px-5 shadow-md"
    >
      <div class="text-white text-sm max-w-xs">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToastAlert',
  inject: ['deviceWidth'],
  props: {
    message: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'default',
      validator: (value) =>
        ['success', 'info', 'warning', 'error', 'default'].includes(value),
    },
    useHtml: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'bottom',
      validator: (value) => ['top', 'center', 'bottom'].includes(value),
    },
  },
  emits: ['onClick'],
  methods: {
    onClick() {
      this.$emit('onClick')
    },
  },
}
</script>
