<template>
  <SplashScreen v-if="showSplashScreen" />
  <template v-else-if="showInfo">
    <section
      id="content"
      class="z-10 flex h-screen flex-col items-center bg-white px-4"
    >
      <div class="-mt-8 flex justify-center">
        <img src="@/assets/image/icon.png" class="h-40 rounded-lg" />
      </div>
      <div class="flex flex-col justify-center px-2">
        <p class="text-sm text-gray-800">
          Pastikan kamu membaca langkah - langkah pemesanan di Gromura sebelum
          melanjutkan:
        </p>
        <p class="mt-4 text-sm text-gray-500">
          1.) Pilih Produk yang ingin kamu pesan
        </p>
        <p class="mt-2 text-sm text-gray-500">2.) Masuk ke halaman keranjang</p>
        <p class="mt-2 text-sm text-gray-500">
          3.) Klik tombol
          <span class="text-green-600">"Pesan melalui Whatsapp"</span>
        </p>
        <p class="mt-2 text-sm text-gray-500">
          4.) Admin kami akan memproses pesanan kamu
        </p>
        <p class="mt-2 text-sm text-gray-500">
          5.) Pesanan selesai dibuat & tunggu pesanan sampai di tokomu
        </p>
      </div>
      <div class="w-full px-2">
        <div
          class="mt-6 flex w-full flex-col justify-center rounded-md border border-red-200 bg-red-50 p-2"
        >
          <p class="flex text-xs font-bold text-red-600">
            <AlertCircle class="mr-1 h-4 w-4" /> Hati - Hati Penipuan!
          </p>
          <p class="mt-4 text-xs text-gray-500">
            Jika kamu ingin melakukan transfer, Pastikan kamu hanya mentransfer
            ke:
          </p>
          <p class="gray-800 mt-1 text-xs font-bold">
            A/N PT. Karya Kreasi Nasional
          </p>
        </div>
      </div>
      <div class="absolute bottom-0 w-full px-6 pb-16">
        <div
          class="flex w-full cursor-pointer items-center justify-center rounded-md bg-gradient-to-r from-[#13cdc1] to-cyan-500 px-2 py-3 text-center text-white hover:bg-teal-600"
          @click="showInfo = false"
        >
          <DoorOpen class="mr-1 h-4 w-4 text-white" />
          <span class="flex text-sm"> Lanjutkan </span>
        </div>
      </div>
    </section>
  </template>
  <template v-else>
    <HeaderNavBarHome
      :key="$route.params.slug"
      :title="$t('home')"
      :cart-count="productDataSelected.length"
      :show-trolly="true"
      :show-notif="false"
      :show-balance="false"
      :show-points="false"
      :ping-signal="pingSignal"
      @open-trolly="openTrolly"
      @search-data="searchFilter"
    />
    <section
      id="content"
      class="container mx-auto flex flex-col bg-white px-4 pt-32"
    >
      <div
        class="mt-6 flex w-full flex-col justify-center rounded-md border border-orange-200 bg-orange-50 p-2"
      >
        <p class="flex text-xs font-bold text-orange-600">
          <Info class="mr-1 h-4 w-4" /> Harga yang tertera adalah harga retail
        </p>
        <p class="mt-3 text-xs text-gray-500">
          Untuk pembelian partai besar harap konfirmasi ke admin ketika
          pemesanan
          <span class="font-semibold text-gray-600"
            >(untuk mendapatkan harga spesial)</span
          >
        </p>
      </div>
      <div class="mb-2 mt-2 flex w-full">
        <span
          v-if="filterData.search !== null && filterData.search !== ''"
          class="text-xs text-gray-400"
          >Mencari produk ({{ filterData.search }})...</span
        >
      </div>
      <div class="intro-y flex w-full flex-col">
        <div
          v-if="productData.length === 0"
          class="grid grid-cols-12 gap-5 pb-8"
        >
          <div
            class="relative col-span-12 flex h-24 items-center justify-center rounded-md border border-dashed border-gray-300 py-5 text-center"
          >
            <span class="mb-2 block text-xs font-medium text-gray-400">
              Tidak ada produk yang dapat ditampilkan
            </span>
          </div>
        </div>
        <div v-else class="flex w-full flex-col pb-8">
          <div class="grid grid-cols-2 gap-4">
            <div
              v-for="item in productData"
              :key="item.product.id"
              class="box flex h-full w-full flex-col rounded-md shadow-md"
            >
              <div class="flex w-full">
                <ImageLoader
                  :image="
                    item.product.images !== undefined &&
                    item.product.images.length > 0
                      ? item.product.images[0].image
                      : null
                  "
                  :alt="item.product.name"
                  rounded-class="rounded-tr-md rounded-tl-md w-full cursor-pointer"
                  @click="productPreview(item)"
                />
              </div>
              <div class="flex h-full w-full flex-col justify-between">
                <div class="flex w-full flex-col px-2 pb-4 pt-2 md:px-4">
                  <p
                    class="line-clamp-1 text-ellipsis text-sm font-medium text-gray-700"
                  >
                    {{ item.product.name }}
                  </p>
                  <div class="flex w-full text-sm font-medium text-red-500">
                    <Currency
                      :total-in-string="item.product.price.toString()"
                    />
                  </div>
                  <div
                    class="mt-2 flex items-center border-t pt-2 text-xs text-green-600"
                  >
                    <Layers class="mr-2 h-4 w-4" /> Stok:
                    {{ item.stock }}
                  </div>
                </div>
                <div
                  v-if="item.stock !== 0"
                  class="flex cursor-pointer items-center justify-center rounded-bl-md rounded-br-md bg-gradient-to-r from-[#13cdc1] to-cyan-500 px-2 py-3 text-center text-white hover:bg-teal-600"
                  @click="addItem(item)"
                >
                  <PlusCircle class="mr-1 h-4 w-4 text-white" />
                  <span class="flex text-xs"> Tambahkan </span>
                </div>
                <div
                  v-else
                  class="flex items-center justify-center rounded-bl-md rounded-br-md bg-gray-500 px-2 py-3 text-center text-white md:py-2 lg:py-3"
                >
                  <AlertCircle class="mr-1 h-4 w-4 text-white" />
                  <span class="flex text-xs"> Stok Habis </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <ModalBasic
      v-if="showTrolly"
      class="py-8"
      title="Keranjang Belanja"
      :show="showTrolly"
      :show-full="true"
      :show-header="true"
      :show-close="true"
      :can-close="true"
      @close="showTrolly = false"
    >
      <div
        v-if="productDataSelected.length === 0"
        class="flex w-full px-4 pb-8"
      >
        <div
          class="relative mt-4 flex h-72 w-full items-center justify-center rounded-md border border-dashed border-gray-300 py-5 text-center"
        >
          <span class="mb-2 block text-xs font-medium text-gray-400">
            Belum ada produk yang dipilih
          </span>
        </div>
      </div>
      <div v-else class="flex w-full flex-col">
        <div
          v-for="item in productDataSelected"
          :key="item.product.id"
          class="flex flex-col"
        >
          <div class="flex w-full">
            <div class="flex w-20 pb-2 pl-4 pt-4">
              <div class="w-full">
                <ImageLoader
                  :image="
                    item.product.images !== undefined &&
                    item.product.images.length > 0
                      ? item.product.images[0].image
                      : null
                  "
                  :alt="item.product.name"
                  class="w-16"
                  rounded-class="rounded-md cursor-pointer"
                  @click="productPreview(item)"
                />
              </div>
            </div>
            <div class="flex w-full flex-col pb-2 pl-4 pt-4 md:px-4">
              <div class="text-md flex w-full text-sm font-medium">
                {{ item.product.name }}
                {{
                  item.product.product_converters !== undefined &&
                  item.product.product_converters.length > 0 &&
                  item.product.product_converters[0].product_unit !== undefined
                    ? `(${item.product.product_converters[0].product_unit.name})`
                    : ''
                }}
              </div>
              <div class="mt-1 flex w-full">
                <span class="flex items-center text-xs text-green-500">
                  {{ item.product.brand.name }}
                </span>
              </div>
              <div
                class="mt-2 flex w-full border-t pt-1 text-xs font-medium text-red-500"
              >
                <Currency :total-in-string="item.product.price.toString()" />
              </div>

              <div class="mb-2 mt-4 flex">
                <div class="flex">
                  <button
                    type="button"
                    class="btn dark:border-darkmode-500 dark:bg-darkmode-700 mr-1 h-8 w-8 rounded-md border-slate-200 bg-slate-100 text-slate-500"
                    @click="changeQuantity(item, false)"
                  >
                    -
                  </button>
                  <input
                    id="pos-form-4"
                    v-model="item.quantity"
                    type="number"
                    class="form-control w-16 rounded-md border-gray-300 text-center text-xs [appearance:textfield] focus:border-gray-400 focus:border-teal-400 focus:text-gray-500 focus:ring focus:ring-gray-100 focus:ring-opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                    placeholder="0"
                    @input="calculateTotalPriceItem(item)"
                  />
                  <button
                    type="button"
                    class="btn dark:border-darkmode-500 dark:bg-darkmode-700 ml-1 h-8 w-8 rounded-md border-slate-200 bg-slate-100 text-slate-500"
                    @click="changeQuantity(item, true)"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>

            <div class="flex w-12 justify-end py-2 pr-4">
              <a
                class="mt-2 flex text-red-500"
                href="javascript:;"
                @click="removeItem(item)"
              >
                <Trash2 class="h-5 w-5" />
              </a>
            </div>
          </div>
          <div class="flex w-full flex-col border-t p-2 py-3 pl-4">
            <div class="flex w-full justify-between text-xs">
              <div class="flex items-center">Total</div>
              <div class="flex items-end pr-2">
                <Currency :total-in-string="item.total_price.toString()" />
              </div>
            </div>
          </div>
          <div class="h-3 w-full bg-gray-100"></div>
        </div>
        <div class="flex w-full flex-col px-4 pb-24">
          <div class="mt-4 rounded-lg border border-teal-200 bg-teal-50 p-5">
            <div class="flex">
              <div class="mr-auto text-xs text-gray-600">Subtotal</div>
              <div class="font-medium">
                <Currency
                  :total-in-string="subTotal.toString()"
                  class="text-xs"
                />
              </div>
            </div>
            <div class="mt-4 flex">
              <div class="mr-auto text-xs text-gray-600">Tax ({{ tax }}%)</div>
              <div class="font-medium">
                <Currency
                  :total-in-string="(total - subTotal).toString()"
                  class="text-xs"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="absolute bottom-0 flex w-full justify-between border-t bg-white px-4 py-4"
      >
        <div class="flex flex-col">
          <div class="mr-auto text-xs font-medium text-gray-600">
            Total Harga
          </div>
          <div class="flex text-base font-medium">
            <Currency :total-in-string="total.toString()" />
          </div>
        </div>
        <div class="flex">
          <div
            class="flex w-40 cursor-pointer items-center justify-center rounded-md bg-gradient-to-r from-[#13cdc1] to-cyan-500 py-1.5 text-sm text-white"
            @click="createTransaction"
          >
            <svg
              v-if="isFormLoading"
              class="-ml-1 mr-3 h-5 w-5 animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <div class="flex flex-col items-center">
              <span class="text-xs">Pesan melalui</span>
              <span class="text-sm">Whatsapp</span>
            </div>
          </div>
        </div>
      </div>

      <Toast
        v-if="messageAlert.show"
        :type="messageAlert.type"
        :message="messageAlert.message"
        :position="messageAlert.position"
      />
      <div id="success-notification" class="toastify-content flex hidden">
        <CheckCircle class="text-green-500" />
        <div class="ml-4 mr-4">
          <div class="font-medium">{{ status }}</div>
          <div class="mt-1 text-slate-500">
            {{ message }}
          </div>
        </div>
      </div>
      <div id="failed-notification" class="toastify-content flex hidden">
        <!-- <XCircleIcon class="text-danger" /> -->
        <div class="ml-4 mr-4">
          <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
          <div class="font-medium">{{ status }}</div>
          <div class="mt-1 text-slate-500">{{ message }}</div>
        </div>
      </div>
    </ModalBasic>
  </template>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helper as $h } from '@/utils/helper'
import globalMixin from '@/mixins/global.js'
import SplashScreen from '@/components/SplashComponent.vue'
import HeaderNavBarHome from '@/components/navbar/HeaderNavBarHome.vue'
import ModalBasic from '@/components/modal/ModalBasic.vue'
import Button from '@/components/button/Button.vue'
import Toast from '@/components/alert/Toast.vue'

import Toastify from 'toastify-js'
import {
  Search,
  PlusCircle,
  AlertCircle,
  DoorOpen,
  Layers,
  Repeat2,
  Trash2,
  XCircle,
  CheckCircle,
  Info,
} from 'lucide-vue-next'
import ImageLoader from '@/components/loader/ImageLoader.vue'
import Currency from '@/components/currency/Currency.vue'
import ShimmerCard from '@/components/loader/ShimmerCard.vue'

export default {
  name: 'HomePage',
  components: {
    SplashScreen,
    HeaderNavBarHome,
    ModalBasic,
    ImageLoader,
    Currency,
    ShimmerCard,
    Search,
    PlusCircle,
    AlertCircle,
    DoorOpen,
    Layers,
    Button,
    Trash2,
    XCircle,
    CheckCircle,
    Info,
    Toast,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const storeId = ref('c7bfe8bb-27ce-423c-8fb1-af51136e86a7')

    const showSplashScreen = ref(true)
    const showInfo = ref(false)
    const showTrolly = ref(false)

    let transaction = reactive({})
    const activeProductStock = reactive({})

    const tax = ref(11)
    const subTotal = ref(0)
    const total = ref(0)

    const isFormLoading = ref(false)
    const isLoading = ref(false)

    const pingSignal = ref(false)
    const timer = ref(undefined)

    const productPreviewModal = ref(false)
    const detailTrxModal = ref(false)
    const cartPreview = ref(false)

    const status = ref('')
    const message = ref('')

    let messageAlert = reactive({
      show: false,
      message: '',
      type: 'info',
      position: 'center',
    })

    let productData = ref([])
    let productDataSelected = ref([])

    let metaData = reactive({
      page: 1,
      take: 6,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 12,
      search: '',
      order: 'ASC',
      // type: null,
    })

    onMounted(async () => {
      // const storeId = store.getters['store/storeId']
      setTimeout(() => {
        showSplashScreen.value = false
        showInfo.value = true
      }, 2000)

      if (storeId.value === null) {
        setTimeout(() => {
          getProduct()
        }, 2000)
      } else {
        getProduct()
      }
    })

    const getProduct = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        productData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }

      await store
        .dispatch('productStock/getByStore', {
          store_id: storeId.value,
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          q: filterData.search,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            productData.value = [...productData.value, ...responseData.data]
          }

          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }

          const meta = responseData.meta
          if (meta.hasNextPage === true) {
            filterData.page += 1
            getProduct()
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const searchFilter = (q) => {
      filterData.search = q

      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getProduct(true)
      }, 1000)
    }

    const productPreview = (data) => {
      Object.assign(activeProductStock, data)
      productPreviewModal.value = true
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const resetForm = () => {
      subTotal.value = 0
      total.value = 0

      productData.value = []
      productDataSelected.value = []
    }

    const addItem = (product) => {
      pingSignal.value = true
      if (productDataSelected.value.length === 0) {
        product.quantity = null
        product.price = null
        // product.quantity_bonus = null
        product.price_discount = null
        product.total_price = 0
        productDataSelected.value = [product]
      } else {
        let isDuplicate = false
        productDataSelected.value.forEach((item) => {
          if (item.id === product.id) {
            isDuplicate = true
          }
        })
        if (isDuplicate === false) {
          product.quantity = null
          product.price = null
          // product.quantity_bonus = null
          product.price_discount = null
          product.total_price = 0
          productDataSelected.value = [...productDataSelected.value, product]
        }
      }

      setTimeout(() => {
        pingSignal.value = false
      }, 1500)
    }

    const removeItem = (product) => {
      productDataSelected.value = productDataSelected.value.filter(
        (item) => item.id != product.id
      )
    }

    const changeQuantity = (product, added) => {
      if (added === true) {
        if (product.quantity === null) {
          product.quantity = 1
        } else {
          product.quantity = product.quantity + 1
        }
      } else {
        if (product.quantity !== 0) {
          product.quantity = product.quantity - 1
        }
      }

      calculateTotalPriceItem(product)
    }

    const calculateTotalPriceItem = (productStock) => {
      productStock.total_price =
        productStock.quantity * productStock.product.price -
        productStock.price_discount

      calculateTotalPrice()
    }

    const calculateTotalPrice = () => {
      let totalPrice = 0
      productDataSelected.value.forEach((item) => {
        totalPrice += item.total_price
      })

      if (tax.value.toString() !== '0') {
        subTotal.value = totalPrice - Math.round((totalPrice / 100) * tax.value)
      } else {
        subTotal.value = totalPrice
      }

      total.value = totalPrice
    }

    const createTransaction = async () => {
      if (productDataSelected.value.length === 0) {
        // status.value = 'Gagal'
        // message.value = 'Mohon untuk menambahkan item terlebih dahulu'
        // setTimeout(() => {
        //   showToast()
        // }, 200)

        Object.assign(messageAlert, {
          show: true,
          message: 'Mohon untuk menambahkan item terlebih dahulu',
          type: 'error',
          position: 'center',
        })
        setTimeout(() => {
          messageAlert.show = false
        }, 3000)
        return
      }

      let dataNotValid = false
      productDataSelected.value.forEach((item) => {
        if (item.quantity === 0 || item.quantity === null) {
          dataNotValid = true
        }
      })

      if (dataNotValid === true) {
        // status.value = 'Gagal'
        // message.value = 'Quantity tidak boleh kosong'
        // setTimeout(() => {
        //   showToast()
        // }, 200)

        Object.assign(messageAlert, {
          show: true,
          message: 'Quantity tidak boleh kosong',
          type: 'error',
          position: 'center',
        })
        setTimeout(() => {
          messageAlert.show = false
        }, 3000)
        return
      }

      if (productDataSelected.value.length === 0) {
        return
      }

      isFormLoading.value = true

      let index = 1
      let products = ''
      const enter = '%0a'

      productDataSelected.value.forEach((item) => {
        let unit = ''
        if (
          item.product.product_converters !== undefined &&
          item.product.product_converters.length > 0 &&
          item.product.product_converters[0].product_unit !== undefined
        ) {
          unit = item.product.product_converters[0].product_unit.name
        }
        products += `${enter}${index}.) ${item.product.name} - (${item.quantity} ${unit})`

        index += 1
      })

      const waBaseURL = 'https://wa.me/'
      const phone = '6287875475983'
      let waMessage = `Halo Gromura,${enter}${enter}Saya ingin memesan produk dibawah ini:
        ${products}${enter}${enter}Mohon segera diproses yak,${enter}Terimakasih
      `
      let waLink = `${waBaseURL}${phone}?text=${waMessage}`

      window.open(waLink, '_blank')

      showTrolly.value = false
      isFormLoading.value = false
      productDataSelected.value = []
      filterData.search = ''
    }

    const openTrolly = (event) => {
      showTrolly.value = event
    }

    const onSearch = (q) => {
      productData.value = []
      filterData.search = q
      getProduct(true)
    }

    return {
      t,
      n,
      store,
      route,
      router,

      showSplashScreen,
      showInfo,
      showTrolly,

      transaction,
      activeProductStock,

      productPreviewModal,
      productPreview,
      detailTrxModal,
      cartPreview,

      tax,
      subTotal,
      total,

      isFormLoading,
      isLoading,

      status,
      message,

      metaData,
      filterData,

      productData,
      productDataSelected,

      addItem,
      removeItem,
      changeQuantity,
      calculateTotalPriceItem,
      calculateTotalPrice,

      createTransaction,
      resetForm,

      getProduct,

      searchFilter,
      messageAlert,
      showToast,

      pingSignal,
      timer,

      openTrolly,
      onSearch,
    }
  },
  data: () => ({}),
  computed: {},
  methods: {},
}
</script>
