<template>
  <div
    v-if="openAlert"
    class="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1"
  >
    <div
      class="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
      aria-hidden="true"
    >
      <div
        class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#13cdc1] to-cyan-300 opacity-30"
        style="
          clip-path: polygon(
            74.8% 41.9%,
            97.2% 73.2%,
            100% 34.9%,
            92.5% 0.4%,
            87.5% 0%,
            75% 28.6%,
            58.5% 54.6%,
            50.1% 56.8%,
            46.9% 44%,
            48.3% 17.4%,
            24.7% 53.9%,
            0% 27.9%,
            11.9% 74.2%,
            24.9% 54.1%,
            68.6% 100%,
            74.8% 41.9%
          );
        "
      ></div>
    </div>
    <div
      class="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
      aria-hidden="true"
    >
      <div
        class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#13cdc1] to-cyan-300 opacity-30"
        style="
          clip-path: polygon(
            74.8% 41.9%,
            97.2% 73.2%,
            100% 34.9%,
            92.5% 0.4%,
            87.5% 0%,
            75% 28.6%,
            58.5% 54.6%,
            50.1% 56.8%,
            46.9% 44%,
            48.3% 17.4%,
            24.7% 53.9%,
            0% 27.9%,
            11.9% 74.2%,
            24.9% 54.1%,
            68.6% 100%,
            74.8% 41.9%
          );
        "
      ></div>
    </div>
    <div class="flex flex-wrap items-center gap-x-4 gap-y-2">
      <p class="text-sm leading-6 text-gray-900 hover:text-cyan-600">
        <strong class="font-bold"
          >&copy;{{ new Date().getFullYear() }} Gromura</strong
        ><svg
          viewBox="0 0 2 2"
          class="mx-2 inline h-0.5 w-0.5 fill-current"
          aria-hidden="true"
        >
          <circle cx="1" cy="1" r="1" /></svg
        >
        <strong class="font-bold">Hati - Hati Penipuan!</strong> Pastikan anda hanya mentransfer ke A/N PT. Karya Kreasi Nasional
      </p>
      <!-- <a
        href="https://maps.app.goo.gl/mFUiT6xfADfFpq1a8"
        target="_blank"
        class="flex-none rounded-full bg-gradient-to-br from-[#13cdc1] to-cyan-400 px-3.5 py-1 text-xs font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >Kunjungi Outlet Kami <span aria-hidden="true">&rarr;</span></a
      > -->
    </div>
    <div class="flex flex-1 justify-end">
      <button
        type="button"
        class="-m-3 p-3 focus-visible:outline-offset-[-4px]"
        @click="openAlert = false"
      >
        <span class="sr-only">Dismiss</span>
        <svg
          class="h-5 w-5 text-gray-900"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
          />
        </svg>
      </button>
    </div>
  </div>

  <header class="bg-white">
    <nav
      class="container mx-auto flex items-center justify-between py-6 px-6 md:px-0"
      aria-label="Global"
    >
      <div class="flex lg:flex-1">
        <a href="#" class="-m-1.5 p-1.5">
          <span class="sr-only">Gromura</span>
          <img
            class="h-8 w-auto lg:h-10"
            src="@/assets/image/logo.png"
            alt="Gromura"
          />
        </a>
      </div>
      <div class="flex lg:hidden">
        <button
          type="button"
          class="-m-2.5 inline-flex items-center justify-center rounded-lg p-2.5 text-gray-700"
          @click="openMenu = !openMenu"
        >
          <span class="sr-only">Open main menu</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-x-12">
        <a
          class="hover:text-gromura-primary cursor-pointer text-sm font-semibold leading-6 text-gray-900"
          :class="$route.name === 'home' && 'text-gromura-primary'"
          @click="openRoute('home')"
          >Beranda</a
        >
        <a
          class="hover:text-gromura-primary cursor-pointer text-sm font-semibold leading-6 text-gray-900"
          :class="$route.name === 'partnership' && 'text-gromura-primary'"
          @click="openRoute('partnership')"
          >Kemitraan</a
        >
        <a
          class="hover:text-gromura-primary cursor-pointer text-sm font-semibold leading-6 text-gray-900"
          :class="$route.name === 'warehouse' && 'text-gromura-primary'"
          @click="openRoute('warehouse')"
          >Gudang Kami</a
        >
        <div class="relative">
          <button
            type="button"
            class="hover:text-gromura-primary flex cursor-pointer items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900"
            :class="
              ['about-us-our-team', 'about-us-our-vision-mission'].includes(
                $route.name
              ) && 'text-gromura-primary'
            "
            aria-expanded="false"
            @click="dropdown1 = !dropdown1"
          >
            Tentang Kami
            <svg
              :class="{ 'rotate-180': dropdown1 === true }"
              class="h-5 w-5 flex-none text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clip-rule="evenodd"
              />
            </svg>
          </button>

          <div
            v-if="dropdown1"
            x-transition:enter="transition ease-out duration-200"
            x-transition:enter-start="opacity-0 translate-y-1"
            x-transition:enter-end="opacity-100 translate-y-0"
            x-transition:leave="transition ease-in duration-150"
            x-transition:leave-start="opacity-100 translate-y-0"
            x-transition:leave-end="opacity-0 translate-y-1"
            class="absolute -left-8 top-full z-10 mt-3 w-48 max-w-md overflow-hidden rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5"
            @mouseleave="dropdown1 = false"
          >
            <div class="p-2">
              <div
                class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
              >
                <div class="flex-auto">
                  <a
                    class="hover:text-gromura-primary block cursor-pointer font-semibold text-gray-900"
                    :class="
                      $route.name === 'about-us-our-vision-mission' &&
                      'text-gromura-primary'
                    "
                    @click="openRoute('about-us-our-vision-mission')"
                  >
                    Visi & Misi Kami
                    <span class="absolute inset-0"></span>
                  </a>
                </div>
              </div>
              <div
                class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
              >
                <div class="flex-auto">
                  <a
                    class="hover:text-gromura-primary block cursor-pointer font-semibold text-gray-900"
                    :class="
                      $route.name === 'about-us-our-team' &&
                      'text-gromura-primary'
                    "
                    @click="openRoute('about-us-our-team')"
                  >
                    Tim Luar Biasa Kami
                    <span class="absolute inset-0"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          class="hover:text-gromura-primary cursor-pointer text-sm font-semibold leading-6 text-gray-900"
          :class="$route.name === 'contact-us' && 'text-gromura-primary'"
          @click="openRoute('contact-us')"
          >Kontak Kami</a
        >
      </div>
      <div class="hidden lg:flex lg:flex-1 lg:justify-end">
        <a
          class="hover:text-gromura-primary cursor-pointer text-sm font-semibold leading-6 text-gray-900"
          @click="login"
          >Masuk <span aria-hidden="true">&rarr;</span></a
        >
      </div>
    </nav>

    <!-- Mobile Menu -->

    <div v-if="openMenu" class="lg:hidden" role="dialog" aria-modal="true">
      <div class="fixed inset-0 z-10"></div>
      <div
        class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
      >
        <div class="flex items-center justify-between">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Gromura</span>
            <img class="h-10 w-auto" src="@/assets/image/logo.png" alt="" />
          </a>
          <button
            type="button"
            class="-m-2.5 rounded-lg p-2.5 text-gray-700"
            @click="openMenu = false"
          >
            <span class="sr-only">Close menu</span>
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <a
                class="-mx-3 block cursor-pointer rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900"
                :class="$route.name === 'home' && 'text-gromura-primary'"
                @click="openMenu = false, openRoute('home')"
                >Beranda</a
              >
              <a
                class="-mx-3 block cursor-pointer rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900"
                :class="$route.name === 'partnership' && 'text-gromura-primary'"
                @click="openMenu = false, openRoute('partnership')"
                >Kemitraan</a
              >
              <a
                class="-mx-3 block cursor-pointer rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900"
                :class="$route.name === 'warehouse' && 'text-gromura-primary'"
                @click="openMenu = false, openRoute('warehouse')"
                >Gudang Kami</a
              >
              <div class="-mx-3">
                <button
                  type="button"
                  class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900"
                  :class="
                    [
                      'about-us-our-team',
                      'about-us-our-vision-mission',
                    ].includes($route.name) && 'text-gromura-primary'
                  "
                  aria-controls="disclosure-1"
                  aria-expanded="false"
                  @click="dropdown2 = !dropdown2"
                >
                  Tentang Kami
                  <svg
                    :class="{ 'rotate-180': dropdown2 === true }"
                    class="h-5 w-5 flex-none"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
                <!-- 'Product' sub-menu, show/hide based on menu state. -->
                <div
                  v-if="dropdown2"
                  id="disclosure-1"
                  x-transition:enter="transition ease-out duration-200"
                  x-transition:enter-start="opacity-0 translate-y-1"
                  x-transition:enter-end="opacity-100 translate-y-0"
                  x-transition:leave="transition ease-in duration-150"
                  x-transition:leave-start="opacity-100 translate-y-0"
                  x-transition:leave-end="opacity-0 translate-y-1"
                  class="mt-2 space-y-2"
                  @mouseleave="dropdown2 = false"
                >
                  <a
                    class="block cursor-pointer rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900"
                    :class="
                      $route.name === 'about-us-our-vision-mission' &&
                      'text-gromura-primary'
                    "
                    @click="openMenu = false, openRoute('about-us-our-vision-mission')"
                    >Visi & Misi</a
                  >
                  <a
                    class="block cursor-pointer rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900"
                    :class="
                      $route.name === 'about-us-our-team' &&
                      'text-gromura-primary'
                    "
                    @click="openMenu = false, openRoute('about-us-our-team')"
                    >Kontak Kami</a
                  >
                </div>
              </div>

              <a
                class="-mx-3 block cursor-pointer rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900"
                :class="$route.name === 'contact-us' && 'text-gromura-primary'"
                @click="openMenu = false, openRoute('contact-us')"
                >Kontak Kami</a
              >
            </div>
            <div class="py-6">
              <a
                class="-mx-3 block cursor-pointer rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900"
                @click="openMenu = false, login"
                >Masuk <span aria-hidden="true">&rarr;</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import globalMixin from '@/mixins/global.js'
// import LogoIcon from '@/assets/svg/logo.svg'
import ArrowDownIcon from '@/assets/svg/arrow-down.svg'
import HamburgerIcon from '@/assets/svg/hamburger.svg'
import CloseIcon from '@/assets/svg/close.svg'

export default {
  name: 'NavbarDefault',
  components: { ArrowDownIcon, HamburgerIcon, CloseIcon },
  mixins: [globalMixin],
  data: () => ({
    openAlert: true,
    dropdown1: false,
    dropdown2: false,
  }),
  methods: {
    async login() {
      window.open
      this.isLoading = true
      window.location.href = `https://app.gromura.com/login`
    },
  },
}
</script>
