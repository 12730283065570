<template>
  <div :class="wrapClass">
    <div class="-mt-1 w-10 cursor-pointer">
      <div
        class="absolute z-50 -mt-1 ml-1 flex h-4 w-4 transform items-center justify-center rounded-full bg-red-500 text-white"
      >
        <span class="text-xs">{{ totalCart }}</span>
      </div>
      <div class="flex justify-end">
        <a
          :class="customClass"
          class="hover:gray-600 p-1 text-sm text-gray-600"
        >
          <ShoppingCartIcon class="inline h-6 w-6" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ShoppingCartIcon } from 'lucide-vue-next'

export default {
  name: 'CartButton',
  components: {
    ShoppingCartIcon,
  },
  props: {
    customClass: {
      type: String,
      required: false,
      default: '',
    },
    wrapClass: {
      type: String,
      required: false,
      default: '',
    },
    totalCart: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: () => ({}),
}
</script>
